// showLastCommitMessageForThisLibrary.js
import { create } from "apisauce"
import moment from "moment"

let serverURL = ""
//export const stripePublicKEY = 'pk_test_pBBOQ8ZKMTIJWMFx5VJw8IMZ00hYtkFfoN'
export const stripePublicKEY =
  process.env.NODE_ENV === "production"
    ? "pk_live_C5mo76XHUlQ3RfZAZVJOsyGD00OMh7skji"
    : "pk_test_pBBOQ8ZKMTIJWMFx5VJw8IMZ00hYtkFfoN"

if (process.env.NODE_ENV === "production") {
  //serverURL = "http://163.172.138.236:8080"
  //serverURL = 'https://server.molokoy.app'
  serverURL = "https://api.molokoy.app"
  //serverURL = 'https://app-server.runwithjey.fr'
} else {
  //  serverURL = 'http://localhost:9000'
  serverURL = window.location.origin.replace("3000", "9000")

  //serverURL = "https://app-server.runwithjey.fr"
}
const access_token = "DO40o7Hii4GqWCnBT17By9silRPIcE7F"
// define the api
var api
function main() {
  try {
    localStorage.getItem && (window.storage = localStorage)
  } catch (exception) {
    return
  }
  api = create({
    baseURL: serverURL,
    //baseURL: 'http://b97fb45c.ngrok.io',
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("RWJToken")
        ? "Bearer " + localStorage.getItem("RWJToken")
        : null,
    },
  })
}
const setAuthHeaders = () => {
  api.setHeaders({
    Authorization: localStorage.getItem("RWJToken")
      ? "Bearer " + localStorage.getItem("RWJToken")
      : null,
  })
}
//main permet de faire un return pour arretrer l'execution si pas de cookies / session
main()

export const login = (email, password) => {
  let tok = email + ":" + password
  let hash = btoa(tok)
  return api.post(
    "/auth",
    { access_token: access_token },
    {
      headers: {
        "Authorization": "Basic " + hash,
        "Content-Type": "application/json",
      },
    }
  )
}
export const signup = (body) => {
  api.setHeaders({
    "Authorization": "Bearer " + access_token,
    "Content-Type": "application/json",
  })
  return api.post("/users", body)
}

export const sendPasswordReset = (email) => {
  api.setHeaders({
    "Authorization": "Bearer " + access_token,
    "Content-Type": "application/json",
  })
  const link =
    window.location.protocol + "//" + window.location.host + "/forgot"
  const body = { email, link }
  return api.post("/password-resets", body)
}
export const PasswordResetTokenCheck = (token) => {
  return api.get(`/password-resets/${token}`)
}
export const PasswordReinit = (password, token) => {
  return api.put(`/password-resets/${token}/`, { password })
}

export const passwordChange = (email, userId, oldPassword, password) => {
  //router.put('/:id/password', passwordAuth(), body({ password }), updatePassword)
  let tok = email + ":" + oldPassword
  let hash = btoa(tok)
  api.setHeaders({
    "Authorization": "Basic " + hash,
    "Content-Type": "application/json",
  })
  return api.put(`/users/${userId}/password`, { password })
}

export const getMe = () => {
  setAuthHeaders()
  return api.get("/users/me")
}
export const getMyAthletes = () => {
  setAuthHeaders()
  return api.get("/users/my?&sort=name&limit=500")
}
export const searchMyAthletes = (pattern) => {
  setAuthHeaders()
  return api.get(`/users/my?q=${pattern}&sort=name&limit=100`)
}

export const getMyActiveAthletes = () => {
  setAuthHeaders()
  return api.get("/users/my-active?&sort=name&limit=100")
}
export const getAllAthletes = () => {
  setAuthHeaders()
  return api.get("/users?&sort=name")
}
export const getAthlete = (userId) => {
  setAuthHeaders()
  return api.get(`/users/${userId}`)
}

export const updateHomeButtons = (userId, body) => {
  setAuthHeaders()

  return api.put(`/users/${userId}/home-buttons`, body)
}

export const getAthletesCourses = (userId) => {
  setAuthHeaders()
  return api.get(`/courses/user/${userId}?&sort=-date`)
}

export const getMeCourses = () => {
  setAuthHeaders()
  return api.get(`/courses/user/me?&sort=-date`)
}

export const getFaults = () => {
  setAuthHeaders()
  return api.get("/faults/")
}
export const createFault = (body) => {
  setAuthHeaders()
  return api.post("/faults/", body)
}
export const getDrills = () => {
  setAuthHeaders()
  //return api.get('/drills/?&sort=name&limit=100')
  return api.get("/drills/")
}
export const getDrillsByCategory = (category) => {
  setAuthHeaders()
  return api.get(`/drills/category/${category}?&sort=name&limit=100`)
}
export const getProtocolsByType = (type) => {
  setAuthHeaders()
  return api.get(`/protocols/byType/${type}/?&sort=name&limit=100`)
}

export const getDrill = (id) => {
  setAuthHeaders()
  return api.get("/drills/" + id)
}

export const reportDrillIssue = (id) => {
  setAuthHeaders()
  return api.get("/drills/issue/" + id)
}

export const createDrill = (body) => {
  setAuthHeaders()
  return api.post("/drills/", body)
}

export const updateDrill = (id, body) => {
  setAuthHeaders()

  return api.put("/drills/" + id, body)
}

export const getMyCues = () => {
  setAuthHeaders()
  return api.get("/cues/my")
}

export const getCues = () => {
  setAuthHeaders()
  return api.get("/cues/")
}

export const updateUserFault = (userId, faultId) => {
  const faults = {
    faults: {
      fault: faultId,
    },
  }
  setAuthHeaders()
  return api.put(`/users/${userId}/add-fault/`, faults)
}

export const setMyLocale = (locale) => {
  setAuthHeaders()
  return api.patch(`/users/set-locale/`, { locale })
}

export const updateUserIsActive = (userId, statusString) => {
  const status = {
    isActive: statusString,
  }
  setAuthHeaders()
  return api.put(`/users/${userId}/status/`, status)
}
export const updateProfile = (userId, body) => {
  setAuthHeaders()
  return api.put(`/users/${userId}`, body)
}
export const createPerf = (body) => {
  setAuthHeaders()
  return api.post("/perfs/", body)
}
export const getMyLastPerfs = () => {
  setAuthHeaders()
  return api.get("/perfs/my/")
}
export const getAllMyPerfs = () => {
  setAuthHeaders()
  return api.get("/perfs/my/1000")
}
export const getMyRealPerfsAndFactor = () => {
  setAuthHeaders()
  return api.get("/perfs/my-real-factor")
}
export const deleteMyPerf = (perfId) => {
  setAuthHeaders()
  return api.delete(`/perfs/${perfId}`)
}
export const createAdvice = (body) => {
  setAuthHeaders()
  return api.post("/advices/", body)
}
export const updateAdvice = (body) => {
  setAuthHeaders()
  return api.put("/advices/", body)
}
export const uploadProfilePic = (body) => {
  setAuthHeaders()
  api.setHeaders({
    "Content-Type": "multipart/form-data",
  })
  return api.post("/users/upload/", body)
}
export const getMyCourses = (group) => {
  setAuthHeaders()
  let groupParam = ""
  if (group) {
    groupParam = "&group=" + group
  }
  return api.get("/courses/my?&sort=-date&limit=100" + groupParam)
}

export const getMyGroups = () => {
  setAuthHeaders()

  return api.get("/courses/mygroups")
}

export const getAllCourses = () => {
  setAuthHeaders()
  return api.get("/courses?&sort=-date")
}
export const getCourse = (id) => {
  setAuthHeaders()
  return api.get("/courses/" + id)
}
export const copyCourse = (id) => {
  setAuthHeaders()
  return api.get("/courses/copy/" + id)
}
export const createCourse = (body) => {
  setAuthHeaders()
  return api.post("/courses/", body)
}
export const AddPlayerToClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/add-user", body)
}
export const addDrillToClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/add-drill", body)
}

export const removeDrillFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/del-drill", body)
}

export const addWarmupDrillToClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/add-warmupdrill", body)
}
export const removeWarmupDrillFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/del-warmupdrill", body)
}

export const RemovePlayerFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId + "/del-user", body)
}
export const UpdateClass = (classId, body) => {
  setAuthHeaders()
  return api.put("/courses/" + classId, body)
}
export const getNextClass = () => {
  setAuthHeaders()
  return api.get(
    "/courses/imin/" +
      moment().startOf("day").local().toISOString() +
      "?&sort=date"
  )
}
export const getMyClassById = (id) => {
  setAuthHeaders()
  return api.get("/courses/imin/id/" + id)
}

export const updateCourseIsAvailable = (courseId, statusString) => {
  const status = {
    isAvailable: statusString,
  }
  setAuthHeaders()
  return api.put(`/courses/${courseId}/status/`, status)
}

export const updateGroup = (courseId, groupName) => {
  const body = {
    group: groupName,
  }
  setAuthHeaders()
  return api.put(`/courses/${courseId}/group/`, body)
}

export const deleteCourse = (courseId) => {
  setAuthHeaders()
  return api.delete(`/courses/${courseId}`)
}

export const getNotificationsForMe = () => {
  setAuthHeaders()
  return api.get("/notifications/forme?&sort=-createdAt")
}

export const getUnreadNotificationsForMe = () => {
  setAuthHeaders()
  return api.get("/notifications/forme-unread?&sort=-createdAt")
}
export const getNotificationsSent = () => {
  setAuthHeaders()
  return api.get("/notifications/sent?&sort=-createdAt")
}
export const countNotificationsForMe = () => {
  setAuthHeaders()
  return api.get("/notifications/forme-count")
}
export const setMyNotificationAsRead = () => {
  setAuthHeaders()
  return api.get("/notifications/set-read")
}
export const createNotifications = (body) => {
  setAuthHeaders()
  return api.post("/notifications/many", body)
}
export const setReadNotification = (id) => {
  setAuthHeaders()
  return api.put(`/notifications/${id}`, { read: true })
}

export const deleteNotification = (id) => {
  setAuthHeaders()
  return api.delete(`/notifications/${id}`)
}

export const getAllAnalyses = ({
  selectedUser = null,
  page = 1,
  itemsPerPage,
}) => {
  setAuthHeaders()
  return api.get(
    `/analyses/${
      selectedUser ? "by-user/" + selectedUser + "/" : ""
    }?page=${page}&limit=${itemsPerPage}&sort=-createdAt`
  )
}

export const getTodoAnalyses = ({ selectedUser, page = 1, itemsPerPage }) => {
  setAuthHeaders()
  return api.get(
    `/analyses/todo/${
      selectedUser ? selectedUser + "/" : ""
    }?page=${page}&limit=${itemsPerPage}&sort=-createdAt`
  )
}

export const getMyAnalyses = ({ limit, fields } = {}) => {
  setAuthHeaders()
  return api.get(`/analyses/my/${limit || 100}?${fields && "fields=" + fields}`)
}
export const getMyLastAnalysis = () => {
  setAuthHeaders()
  return api.get("/analyses/my-last")
}

export const getMyAnalysesCreated = () => {
  setAuthHeaders()
  return api.get("/analyses/my-created/1000?&sort=-createdAt")
}
export const createAnalysis = (body) => {
  setAuthHeaders()
  return api.post("/analyses/", body)
}
export const getAnalysis = (id) => {
  setAuthHeaders()
  return api.get("/analyses/" + id)
}
export const updateAnalysis = (analysisId, body) => {
  setAuthHeaders()
  return api.put("/analyses/" + analysisId, body)
}

export const setIsInvalid = (analysisId, body) => {
  setAuthHeaders()
  return api.patch("/analyses/invalid/" + analysisId, body)
}

export const setToUpdate = (analysisId, body) => {
  setAuthHeaders()
  return api.patch("/analyses/to-update/" + analysisId, body)
}

export const setViewedAnalysis = (analysisId) => {
  setAuthHeaders()
  return api.patch("/analyses/viewed/" + analysisId, {})
}

export const deleteAnalysis = (courseId) => {
  setAuthHeaders()
  return api.delete(`/analyses/${courseId}`)
}

export const createTrainingLog = (body) => {
  setAuthHeaders()
  return api.post("/training-log/", body)
}

export const updateTrainingLog = (logId, body) => {
  setAuthHeaders()
  return api.put("/training-log/" + logId, body)
}
export const getTrainingLog = (id) => {
  setAuthHeaders()
  return api.get("/training-log/" + id)
}
export const getTrainingLogForCourse = (courseId) => {
  setAuthHeaders()
  return api.get("/training-log/course/" + courseId)
}

export const getTrainingLogForCourseUser = (courseId, userId) => {
  setAuthHeaders()
  return api.get("/training-log/course-user/" + courseId + "/" + userId)
}

export const pickProtocol = (type, gearUnAvailable) => {
  setAuthHeaders()
  if (gearUnAvailable) {
    gearUnAvailable = encodeURI(gearUnAvailable.join(","))
  } else {
    gearUnAvailable = ""
  }
  //gearUnAvailable = encodeURI(gearUnAvailable.join(','))
  return api.get("/protocols/type/" + type + "/exclude/" + gearUnAvailable)
}
export const pickProtocolFromPlan = (
  type,
  gearUnAvailable /*, gearMandatory*/
) => {
  setAuthHeaders()
  gearUnAvailable = encodeURI(gearUnAvailable.join(","))
  // gearMandatory = encodeURI(gearMandatory.join(','))
  return api.get(
    "/protocols/plan/" + type + "/exclude/" + (gearUnAvailable || "noexlude") //+'/include/' +   gearMandatory
  )
}

export const getProtocol = (id) => {
  setAuthHeaders()
  return api.get("/protocols/" + id)
}

export const getAllProtocols = (type) => {
  setAuthHeaders()
  if (type) {
    return api.get(`/protocols/byType/${type}?&sort=label`)
  } else {
    return api.get("/protocols/?&sort=label")
  }
}

export const createProtocol = (body) => {
  setAuthHeaders()
  return api.post("/protocols/", body)
}
export const addDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/add-drill", body)
}

export const saveWorkout = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/workout", body)
}

export const removeDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/del-drill", body)
}

export const addWarmupDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/add-warmupdrill", body)
}

export const removeWarmupDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/del-warmupdrill", body)
}

export const addFlexibilityDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/add-flexibilitydrill", body)
}

export const removeFlexibilityDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/del-flexibilitydrill", body)
}

export const addLightFlexibilityDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put(
    "/protocols/" + protocolId + "/add-lightflexibilitydrill",
    body
  )
}

export const removeLightFlexibilityDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put(
    "/protocols/" + protocolId + "/del-lightflexibilitydrill",
    body
  )
}

export const addStrengthDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/add-strengthdrill", body)
}

export const removeStrengthDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/del-strengthdrill", body)
}

export const addSelfCareDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/add-selfcaredrill", body)
}

export const removeSelfCareDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId + "/del-selfcaredrill", body)
}

export const updateProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/" + protocolId, body)
}

export const updateProtocolDrills = (protocolId, body) => {
  setAuthHeaders()
  return api.put("/protocols/update-drills/" + protocolId, body)
}

export const updateProtocolIsAvailable = (protocolId, statusString) => {
  const status = {
    isAvailable: statusString,
  }
  setAuthHeaders()
  return api.put(`/protocols/${protocolId}/status/`, status)
}

export const copyProtocol = (id) => {
  setAuthHeaders()
  return api.get("/protocols/copy/" + id)
}

export const deleteProtocol = (id) => {
  setAuthHeaders()
  return api.delete(`/protocols/${id}`)
}

export const getProtocolsPlan = (id) => {
  setAuthHeaders()
  return api.get("/protocols-plan/" + id)
}

export const getMyProtocolsPlan = () => {
  setAuthHeaders()
  return api.get("/protocols-plan/my")
}

export const getProtocolsPlanByLabel = (label) => {
  setAuthHeaders()
  return api.get(`/protocols-plan/label/${label}`)
}

export const getAllProtocolsPlans = () => {
  setAuthHeaders()
  return api.get("/protocols-plan/?&sort=label&limit=100")
}

export const createProtocolsPlan = (body) => {
  setAuthHeaders()
  return api.post("/protocols-plan/", body)
}
export const updateProtocolsPlanUsers = (ProtocolsPlanId, body) => {
  setAuthHeaders()
  return api.put("/protocols-plan/" + ProtocolsPlanId + "/users", body)
}

export const addProtocolToProtocolsPlan = (ProtocolsPlanId, body) => {
  setAuthHeaders()
  return api.put("/protocols-plan/" + ProtocolsPlanId + "/add-protocol", body)
}

export const removeProtocolFromProtocolsPlan = (ProtocolsPlanId, body) => {
  setAuthHeaders()
  return api.put("/protocols-plan/" + ProtocolsPlanId + "/del-protocol", body)
}

export const updateProtocolsPlan = (ProtocolsPlanId, body) => {
  setAuthHeaders()
  return api.put("/protocols-plan/" + ProtocolsPlanId, body)
}

export const deleteProtocolPlan = (id) => {
  setAuthHeaders()
  return api.delete(`/protocols-plan/${id}`)
}

export const logDrills = (body) => {
  setAuthHeaders()
  return api.post("/users/log-drills/", body)
}

export const drillsByMonth = () => {
  setAuthHeaders()
  return api.get("/drills-history/bymonth/")
  //return api.post('/drills-history/', body)
}

export const getS3Credentials = (body) => {
  setAuthHeaders()
  return api.post("/upload/s3credentials/", body)
}

export const uploadVideo = (targetUrl, file, fileType, tracker) => {
  let s3Api = create({
    baseURL: serverURL,
    headers: {
      "Content-Type": fileType,
      "Content-Disposition": "attachment",
    },
  })

  return s3Api.put(targetUrl, file, {
    onUploadProgress: (value) => tracker(value),
  })
}
export const uploadScreenshot = (targetUrl, blob, fileType, tracker) => {
  let s3Api = create({
    baseURL: serverURL,
    headers: {
      "Content-Type": "multipart/form-data", // "application/multipart/form-data; charset=UTF-8"
    },
  })

  return s3Api.put(targetUrl, blob, {
    onUploadProgress: (value) => tracker(value),
  })
}

export const order = (body) => {
  setAuthHeaders()
  body.cancel_url =
    window.location +
    "/cancel?&src=" +
    encodeURIComponent(window.location.pathname)
  body.success_return_base_url = window.location.href.replace(
    window.location.pathname,
    ""
  )
  return api.post("/subscription/pay/", body)
}

export const getPortalUrl = () => {
  setAuthHeaders()
  let body = {
    return_base_url: window.location.href.replace(window.location.pathname, ""),
  }
  return api.post("/subscription/portal/", body)
}

export const getFreeAccess = (subscriptionsInfos) => {
  setAuthHeaders()
  return api.post("/subscription/free/", subscriptionsInfos)
}

export const getSubscriptionStatus = () => {
  setAuthHeaders()
  return api.get("/subscription/status")
}

export const getPricesAndCurrency = (currency) => {
  setAuthHeaders()
  return api.get(`/subscription/prices/${!!currency && currency}`)
}

export const getServices = () => {
  setAuthHeaders()
  return api.get("/services/?&sort=display_order")
}

export const getCoupon = (code) => {
  setAuthHeaders()
  return api.get("/coupons/code/" + code)
}

export const getShoePrescriptions = (countOnly) => {
  setAuthHeaders()
  return api.get(
    `/shoe-specs/?&sort=-createdAt${countOnly ? "&countOnly=true" : ""}`
  )
}

export const createShoePrescription = (body) => {
  setAuthHeaders()
  return api.post("/shoe-specs/", body)
}

export const updateShoePrescription = (shoesPrescriptionId, body) => {
  setAuthHeaders()
  return api.put("/shoe-specs/" + shoesPrescriptionId, body)
}

export const getPreviousShoesList = () => {
  setAuthHeaders()
  return api.get("/shoe-specs/shoes")
}

export const deleteShoePrescription = (id) => {
  setAuthHeaders()
  return api.delete(`/shoe-specs/${id}`)
}

export const createOrUpdateScore = (body) => {
  setAuthHeaders()
  return api.post("/scores-tables/", body)
}

export const logout = () => {
  localStorage.removeItem("RWJUserIsActive")
  localStorage.removeItem("RWJToken")
  localStorage.removeItem("RWJRole")
  localStorage.removeItem("RWJProfilePicVersion")
  localStorage.removeItem("RWJFirstName")
  localStorage.removeItem("RWJPicture")
  localStorage.removeItem("RWJUserName")
  localStorage.removeItem("RWJUserId")
  localStorage.removeItem("RWJExcludeGear")
  localStorage.removeItem("RWJUserIsVendor")
  sessionStorage.removeItem("RWJSubscriptionStatus")
  //  localStorage.removeItem('RWJlocale')
}

export const deleteAccount = () => {
  setAuthHeaders()
  return api.delete("/users/me")
}
