import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { Grid, Label } from "semantic-ui-react"
import { withPolyglot } from "../vendor/polyglot-react"
import moment from "moment"
import "rc-slider/assets/index.css"
import Slider from "rc-slider"

class PaceSpeed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      perfDate: new Date(),
      perfTime: null,
      perfDistance: null,
      unit: window.localStorage.getItem("RWJMileOrKm") || "mi", //km ou mi
      errorFields: [],
      displayPerfHour: false,
      perfHour: 0,
      minute: 6,
      second: 0,
      speed: 10,
      isReal: false,
      isWaiting: false,
    }
  }

  updateValues(value, key) {
    var { minute, second } = this.state
    var mileOrKm = this.state.unit === "km" ? 1000 : 1609
    minute = key === "minute" ? value : minute
    second = key === "second" ? value : second
    var timeInSecond = minute * 60 + second
    var speed = (3600 * mileOrKm) / mileOrKm / timeInSecond

    speed = speed.toFixed(2)
    this.setState({
      ...this.state,
      minute: minute,
      second: second,
      speed: speed,
    })
  }
  roadmap(firstOrSecondHalf) {
    const { polyglot } = this.props
    const roadmapArrayFirst = {
      "100m": 100,
      "200m": 200,
      "300m": 300,
      "400m": 400,
      "500m": 500,
      "600m": 600,
      "800m": 800,
      "1k": 1000,
      "1500m": 1500,
    }
    const roadmapArraySecond = {
      "1600m": 1600,
      "Mile": 1609,
      "2k": 2000,
      "3k": 3000,
      "4k": 4000,
      "5k": 5000,
      "10k": 10000,
      "Semi": 21097,
      "Marathon": 42195,
    }

    let roadmapArray =
      firstOrSecondHalf === "firstHalf" ? roadmapArrayFirst : roadmapArraySecond

    const timesheet = Object.keys(roadmapArray).map((label) => {
      var totalSeconds =
        (roadmapArray[label] * (this.state.minute * 60 + this.state.second)) /
        (this.state.unit === "km" ? 1000 : 1609)
      return (
        <div key={label}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.2em",
              marginRight: 5,
              width: 80,
              display: "inline-block",
            }}
          >
            {polyglot.has(`paceSpeed.${label}`)
              ? polyglot.t(`paceSpeed.${label}`)
              : label}
          </span>
          {moment("1900-01-01 00:00:00")
            .add(totalSeconds, "seconds")
            .format(totalSeconds >= 3600 ? "hh:mm:ss" : "mm:ss")}
        </div>
      )
    })
    return timesheet
  }
  toggleUnit = () => {
    let { unit } = this.state
    let newUnit = unit === "km" ? "mi" : "km"
    this.setState({ unit: newUnit })
  }
  render() {
    const { polyglot } = this.props
    const { unit } = this.state

    return (
      <Fragment>
        <style>
          {`
          .rc-slider {
            margin-top:5px
          }
          .rc-slider-track {
            background-color: red;
          }
          .rc-slider-handle:active {
              border-color: red;
              box-shadow: 0 0 5px red;
          }
          .rc-slider-handle {
            border: solid 2px red;
            background-color: red;
            touch-action: pan-x;
            margin-top: -16px;
            width: 32px;
            height: 32px;
          }
          .rc-slider-handle:active {
            border-color: red;
            box-shadow: 0 0 5pxred;
            cursor: -webkit-grabbing;
            cursor: grabbing;
          }
          .rc-slider-handle:hover {
            border-color: red;
          }
          .ui.checkbox label,.ui.checkbox label:hover{
            background:transparent;
            color:#fff;
            border:none;
          }
        `}
        </style>

        <Grid centered columns={1}>
          <Grid.Column style={{ textAlign: "center" }}>
            <Label
              style={{ userSelect: "none" }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.minute < 7
                    ? this.state.minute + 1
                    : this.state.minute,
                  "minute"
                )
              }
            >
              {moment("1900-01-01 00:00:00")
                .add(this.state.minute, "minute")
                .format("mm")}
            </Label>{" "}
            :
            <Label
              style={{ userSelect: "none" }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.second < 59
                    ? this.state.second + 1
                    : this.state.second,
                  "second"
                )
              }
            >
              {moment("1900-01-01 00:00:00")
                .add(this.state.second, "seconds")
                .format("ss")}
            </Label>
            <Label
              style={{ userSelect: "none" }}
              size="big"
              basic
              onClick={this.toggleUnit}
            >
              min/{unit}
            </Label>
          </Grid.Column>
        </Grid>
        <Grid centered columns={1}>
          <Grid.Column style={{ textAlign: "center" }}>
            <Label
              style={{ userSelect: "none" }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.second < 59
                    ? this.state.second + 1
                    : this.state.second,
                  "second"
                )
              }
            >
              {this.state.speed}
            </Label>
            <Label
              style={{ userSelect: "none" }}
              size="big"
              basic
              onClick={this.toggleUnit}
            >
              {unit}/h
            </Label>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
            >
              <span style={{ userSelect: "none", color: "grey" }}>
                {" "}
                {polyglot.t("paceSpeed.minutes")}
              </span>
              <Slider
                min={unit === "km" ? 2 : 3}
                max={unit === "km" ? 8 : 13}
                defaultValue={0}
                value={this.state.minute}
                onChange={(value) => this.updateValues(value, "minute")}
                style={{ userSelect: "none" }}
              />
              <br />
              <span style={{ userSelect: "none", color: "grey" }}>
                {polyglot.t("paceSpeed.seconds")}
              </span>
              <Slider
                min={0}
                max={59}
                defaultValue={0}
                value={this.state.second}
                onChange={(value) => this.updateValues(value, "second")}
                style={{ userSelect: "none" }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column style={{ color: "#d2d2d2" }}>
              {this.roadmap("firstHalf")}
            </Grid.Column>
            <Grid.Column style={{ color: "#d2d2d2" }}>
              {this.roadmap("secondHalf")}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }
}
PaceSpeed.propTypes = {
  polyglot: PropTypes.object,
}
PaceSpeed = withPolyglot()(PaceSpeed)
export default PaceSpeed
